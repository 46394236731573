import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useContext } from '@nuxtjs/composition-api';
import getErrorMessageTyped from '@@/common/services/getErrorMessageForNotificationDataTyped';
import projectInfraMapCategories from '@/queries/projectInfraMapCategories.gql';
import lotsList from '@/queries/lotsList.gql';
import { MapInfraCategory } from '@/types/ProjectInfraMap';
import type { CommercialOffer } from '@/types/ManagerFavorites';
import type { PortalLot } from '@/types/PortalLot';

interface MapCategory {
  color: string;
  icon: string;
  id: string;
  name: string;
  infraSet: {
    edges: Array<{
      node: MapInfraCategory;
    }>;
  };
}

type Discounts = Record<string, number>;

export const useManagerFavoritesStore = defineStore('managerFavorites', () => {
  const nuxtContext = useContext();

  const changedFlat = ref<PortalLot | undefined>();
  const commercialOffers = ref<Array<CommercialOffer>>([]);
  const commercialOffer = ref<CommercialOffer>();
  const isLoadingContactMeButton = ref(false);
  const isLoadingFindSimilarButton = ref(false);

  function changeFlat (flat: PortalLot | undefined): void {
    changedFlat.value = flat;
  }

  async function getCategories (projectId: string): Promise<Array<MapCategory>> {
    let categories;

    try {
      const {
        body: query
      } = projectInfraMapCategories.loc.source;

      const {
        data: {
          data
        }
      } = await nuxtContext.$portal.post('', {
        query,
        variables: {
          project: projectId
        }
      });

      categories = data?.allInfraCategories?.edges?.length
        ? data.allInfraCategories.edges.map((item: { node: MapInfraCategory }) => {
          const category = {
            ...item?.node,
            icon: item?.node.iconName
          };
          delete category.iconName;

          return category;
        })
        : [];
    } catch (error) {
      console.log('🚀 ~ file: managerFavorites.ts ~ getCategories ~ error', error);
    }

    return categories;
  }

  async function getCommercialOffers (): Promise<void> {
    try {
      const { data } = await nuxtContext.$axios.get<Array<CommercialOffer>>('/api/offers/commercial_offer');

      commercialOffers.value = data;
    } catch (error) {
      console.error('🚀 ~ file: managerFavorites.ts ~ getCommercialOffers ~ e', error);
      nuxtContext.$sentry.captureException(error);
    }
  }

  async function getCommercialOffer (id: number): Promise<void> {
    try {
      const { data } = await nuxtContext.$axios.get<CommercialOffer>(`/api/offers/commercial_offer/${ id }`);

      commercialOffer.value = data;
    } catch (error) {
      console.error('🚀 ~ file: managerFavorites.ts ~ getCommercialOffer ~ e', error);
      nuxtContext.$sentry.captureException(error);
    }
  }

  async function getLotList (ids: Array<string>, discounts: Discounts): Promise<Array<PortalLot>> {
    try {
      const {
        body: query
      } = lotsList.loc.source;

      const {
        data: {
          data
        }
      } = await nuxtContext.$portal.post('', {
        query,
        variables: {
          id: ids,
          discounts
        }
      });

      return data.result.edges
        ?.map((item: { node: Node }) => item.node);
    } catch (error) {
      console.error('🚀 ~ file: managerFavorites.ts ~ getLotList ~ error', error);
      nuxtContext.$sentry.captureException(error);

      return [];
    }
  }

  async function contactMe (offerId: number, globalId: string): Promise<void> {
    isLoadingContactMeButton.value = true;
    try {
      await nuxtContext.$axios.post(`/api/offers/commercial_offer/${ offerId }/contact_me`, {
        globalId
      });
    } catch (error: unknown) {
      console.error('🚀 ~ file: managerFavorites.ts ~ contactMe ~ error', error);
      nuxtContext.$sentry.captureException(error);
      const errorMessage = getErrorMessageTyped(error);
      throw new Error(errorMessage);
    } finally {
      isLoadingContactMeButton.value = false;
    }
  }

  async function findSimilar (offerId: number, globalId: string): Promise<void> {
    try {
      isLoadingFindSimilarButton.value = true;
      await nuxtContext.$axios.post(`/api/offers/commercial_offer/${ offerId }/find_similar`, {
        globalId
      });
    } catch (error: unknown) {
      console.error('🚀 ~ file: managerFavorites.ts ~ findSimilar ~ error', error);
      nuxtContext.$sentry.captureException(error);
      const errorMessage = getErrorMessageTyped(error);
      throw new Error(errorMessage);
    } finally {
      isLoadingFindSimilarButton.value = false;
    }
  }

  async function commercialOfferShare (offerCollectionId: number): Promise<void> {
    try {
      await nuxtContext.$axios.post('/api/offers/commercial_offer/share', {
        offerCollectionId
      });
    } catch (error: unknown) {
      console.error('🚀 ~ file: managerFavorites.ts ~ commercialOfferShare ~ error', error);
      nuxtContext.$sentry.captureException(error);
    }
  }

  return {
    changedFlat,
    commercialOffers,
    commercialOffer,
    isLoadingContactMeButton,
    isLoadingFindSimilarButton,
    changeFlat,
    getCategories,
    getCommercialOffers,
    getCommercialOffer,
    getLotList,
    contactMe,
    findSimilar,
    commercialOfferShare
  };
});
