import { defineStore } from 'pinia';
import getErrorMessageTyped from '@@/common/services/getErrorMessageForNotificationDataTyped';
import type {
  StateChanger
} from 'vue-infinite-loading';
import { PageInfo } from '@/types/PageInfo';

interface City {
  id: number;
  name: string;
  slug: string;
}

interface Project {
  city: string;
  hasAdditionalTemplates: boolean;
  id: number;
  name: string;
  slug: string;
}

interface SelectType {
  id: number;
  text: string;
  value: string | number;
  citySlug?: string;
}

interface BookingPart {
  id: number;
  amocrmId: number;
  agent: never; // FIXME уточнить
  agency: never; // FIXME уточнить
}

interface Meeting {
  booking: BookingPart;
  cityId: number;
  date: string;
  id: number;
  meetingAddress: string;
  meetingLink: string;
  projectId: number;
  propertyType: {
    label: string;
    value: string;
  };
  recordLink: string;
  status: {
    label: string;
    value: string;
  };
  topic: object;
  type: {
    label: string;
    value: string;
  };
}

interface MeetingsResponse {
  result: Array<Meeting>;
  pageInfo: PageInfo;
}

interface Slots {
  date: string;
  times: Array<string>;
}

interface QRCodeData {
  code: string;
  title: string;
  subtitle: string;
  eventDate: string;
  timeslot: string;
  text: string;
}

interface SlotsParams {
  meet: string;
  city: string;
  project: number;
}

interface FormFields {
  city: string | number | null;
  project: string | number | null;
  roomType: string | null;
  meetingType: string | null;
  date: string | null;
  time: string | null;
}

interface State {
  cities: Array<SelectType>;
  projects: Array<SelectType>;
  slots: Array<Slots>;
  meetings: Array<Meeting>;
  pageInfo: PageInfo;
  qrCodeData: QRCodeData | null;
}

export const MEETING_STATUS = {
  CANCELED: 'cancel',
  CONFIRMED: 'confirm'
  // FIXME: собрать все статусы
} as const;
export type MeetingStatusValues = (typeof MEETING_STATUS)[keyof typeof MEETING_STATUS]

export interface RemappedMeeting {
  id: number;
  date: string;
  statusLabel: string;
  statusValue: string;
  project: string | undefined;
  projectId: number;
  city: string | undefined;
  citySlug: string;
  property: string;
  meetingType: string;
  meetingAddress: string;
  meetingLink: string;
  bookingId: number;
}

export const useMeetingsStore = defineStore('meetings', {
  state: (): State => ({
    cities: [],
    projects: [],
    slots: [],
    meetings: [],
    pageInfo: {},
    qrCodeData: null
  }),

  getters: {
    toTable (state) {
      const {
        meetings,
        projects,
        cities
      } = state;

      return meetings.map((meeting) => {
        const {
          id,
          date,
          status,
          projectId,
          cityId,
          propertyType,
          type,
          meetingAddress,
          meetingLink
        } = meeting;

        return {
          id,
          date,
          statusLabel: status.label,
          statusValue: status.value,
          project: projects.find(({ id }) => id === projectId)?.text,
          projectId,
          city: cities.find(({ id }) => id === cityId)?.text,
          citySlug: cities.find(({ id }) => id === cityId)?.citySlug,
          property: propertyType.label,
          meetingType: type.value,
          meetingAddress,
          meetingLink,
          bookingId: 'bookingId' in meeting ? meeting.bookingId : meeting.booking?.id
        } as RemappedMeeting;
      });
    }
  },

  actions: {
    async getCities (): Promise<void> {
      try {
        const {
          data
        } = await this.$axios.get<Array<City>>('api/cities');

        this.cities = data.map(({
          id,
          name,
          slug
        }) => ({
          id,
          text: name,
          value: id,
          citySlug: slug
        }));
      } catch (error) {
        this.$sentry.captureException(error);
        console.error('🚀 ~ file: meetings.ts ~ getCities ~ e', error);
      }
    },

    async getProjects (): Promise<void> {
      try {
        const {
          data
        } = await this.$axios.get<Array<Project>>('api/v2/projects', {
          params: {
            status: 'current'
          }
        });

        this.projects = data.map(({
          name,
          id,
          city
        }) => ({
          id,
          text: name,
          value: id,
          citySlug: city
        }));
      } catch (error) {
        this.$sentry.captureException(error);
        console.error('🚀 ~ file: meetings.ts ~ getProjects ~ error', error);
      }
    },

    async getSlots ({
      meet,
      city,
      project
    }: SlotsParams): Promise<void> {
      try {
        const {
          data
        } = await this.$axios.get('api/meetings/slots', {
          params: {
            meet,
            city,
            project
          }
        });

        this.slots = data;
      } catch (error) {
        this.$sentry.captureException(error);
        console.log('🚀 ~ file: meetings.ts ~ getSlots ~ error', error);
      }
    },

    async submitForm (form: FormFields): Promise<void> {
      const currentUrl = window.location.href;

      try {
        await this.$axios.post('api/meetings', {
          cityId: form.city,
          projectId: form.project,
          type: form.meetingType,
          topic: '',
          date: `${ form.date }T${ form.time }Z`,
          propertyType: form.roomType
        }, {
          headers: { 'Applied-from-url': currentUrl }
        });
        // eslint-disable-next-line
      } catch (error: any) {
        this.$sentry.captureException(error);
        console.log('🚀 ~ file: meetings.ts ~ submitForm ~ error', error);
        const errorMessage = getErrorMessageTyped(error);
        this.$notify.create({
          message: errorMessage,
          type: 'negative'
        });
        throw new Error(errorMessage);
      }
    },

    async getMeetings (page: string): Promise<void> {
      const status = page === 'upcoming'
        ? [
          'confirm',
          'not_confirm',
          'start'
        ]
        : [
          'cancelled',
          'finish'
        ];

      try {
        const {
          data: {
            result,
            pageInfo
          }
        } = await this.$axios.get<MeetingsResponse>('api/meetings', {
          params: {
            status
          }
        });

        this.pageInfo = pageInfo;
        this.meetings = result;
      } catch (error) {
        this.$sentry.captureException(error);
        console.log('🚀 ~ file: meetings.ts ~ getMeetings ~ error', error);
      }
    },

    async patchMeeting ({ id, form }: {id: number; form: Partial<FormFields>}): Promise<Meeting> {
      const url = `/api/meetings/${ id }`;
      const requestParams = {
        date: `${ form.date }T${ form.time }Z`
      };

      try {
        return await this.$axios.$patch(url, requestParams);
      } catch (error) {
        this.$sentry.captureException(error);
        console.log('🚀 ~ file: meetings.ts ~ patchMeeting ~ error', error);

        return Promise.reject(error);
      }
    },

    async deleteMeeting (id: number): Promise<void> {
      const url = `/api/meetings/${ id }/refuse`;

      try {
        await this.$axios.patch(url);
      } catch (error) {
        this.$sentry.captureException(error);
        console.log('🚀 ~ file: meetings.ts ~ deleteMeeting ~ error', error);
      }
    },

    async nextPage ({ page, infiniteState }: {page: PageInfo; infiniteState: StateChanger}): Promise<void> {
      if (!page.next_page) {
        return;
      }

      try {
        const {
          data: {
            result,
            pageInfo
          }
        } = await this.$axios.get<MeetingsResponse>(page.next_page);

        if (!pageInfo) {
          throw new Error('page info not received');
        }

        if (result?.length) {
          this.pageInfo = pageInfo;
          this.meetings = [...this.meetings, ...result];

          // eslint-disable-next-line dot-notation
          if (!pageInfo['next_page']) {
            infiniteState.complete();
          } else {
            infiniteState.loaded();
          }
        } else {
          infiniteState.loaded();
        }
      } catch (error) {
        this.$sentry.captureException(error);
        console.log('🚀 ~ file: meetings.ts ~ ~ nextPage ~ error', error);
        infiniteState.complete();
      }
    },

    async getQRCode (): Promise<void> {
      try {
        const url = 'api/events_list/qr_code/';
        const { data } = await this.$axios.get<QRCodeData | null>(url);

        if (data) {
          this.qrCodeData = data;
        } else {
          this.qrCodeData = null;
        }
      } catch (error) {
        this.$sentry.captureException(error);
        console.log('🚀 ~ file: meetings.ts ~ getQRCode ~ error', error);
      }
    }
  }
});
