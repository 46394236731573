// eslint-disable-next-line
const REGEX_MOBILE1 = /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|FBAN|FBAV|fennec|hiptop|iemobile|ip(hone|od)|Instagram|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i

// eslint-disable-next-line
const REGEX_MOBILE2 = /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i

function isMobile(a) {
    return REGEX_MOBILE1.test(a) || REGEX_MOBILE2.test(a.substr(0, 4));
}

// eslint-disable-next-line
const REGEX_MOBILE_OR_TABLET1 = /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|FBAN|FBAV|fennec|hiptop|iemobile|ip(hone|od)|Instagram|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i
// eslint-disable-next-line
const REGEX_MOBILE_OR_TABLET2 = /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i

function isMobileOrTablet(a) {
    return REGEX_MOBILE_OR_TABLET1.test(a) || REGEX_MOBILE_OR_TABLET2.test(a.substr(0, 4));
}
function isIos(a) {
    return /iPad|iPhone|iPod/.test(a);
}
function isAndroid(a) {
    return /android/i.test(a);
}
function isWindows(a) {
    return /Windows/.test(a);
}

function isMacOS(a) {
    return /Mac OS X/.test(a);
}
// Following regular expressions are originated from bowser(https://github.com/lancedikson/bowser).
// Copyright 2015, Dustin Diaz (the "Original Author")
// https://github.com/lancedikson/bowser/blob/master/LICENSE
const browsers = [
    { name: 'Samsung', test: /SamsungBrowser/i },
    { name: 'Edge', test: /edg([ea]|ios|)\//i },
    { name: 'Firefox', test: /firefox|iceweasel|fxios/i },
    { name: 'Chrome', test: /chrome|crios|crmo/i },
    { name: 'Safari', test: /safari|applewebkit/i },
];
// const DEFAULT_USER_AGENT = '<%= options.defaultUserAgent %>';

function getBrowserName(a) {
    for (const b of browsers) {
        if (b.test.test(a)) {
            return b.name;
        }
    }
    return null;
}


function extractDevices(req, userAgent) {
    let mobile = null;
    let mobileOrTablet = null;
    let ios = null;
    let android = null;
    if (userAgent === 'Amazon CloudFront') {
        if (req.headers['cloudfront-is-mobile-viewer'] === 'true') {
            mobile = true;
            mobileOrTablet = true;
        }
        if (req.headers['cloudfront-is-tablet-viewer'] === 'true') {
            mobile = false;
            mobileOrTablet = true;
        }
    } else if (req && req.headers['cf-device-type']) { // Cloudflare
        switch (req.headers['cf-device-type']) {
            case 'mobile':
                mobile = true;
                mobileOrTablet = true;
                break;
            case 'tablet':
                mobile = false;
                mobileOrTablet = true;
                break;
            case 'desktop':
                mobile = false;
                mobileOrTablet = false;
                break;
        }
    } else {
        mobile = isMobile(userAgent);
        mobileOrTablet = isMobileOrTablet(userAgent);
        ios = isIos(userAgent);
        android = isAndroid(userAgent);
    }
    const windows = isWindows(userAgent);
    const macOS = isMacOS(userAgent);
    const browserName = getBrowserName(userAgent);
    const isSafari = browserName === 'Safari';
    const isFirefox = browserName === 'Firefox';
    const isEdge = browserName === 'Edge';
    const isChrome = browserName === 'Chrome';
    const isSamsung = browserName === 'Samsung';
    return { mobile, mobileOrTablet, isSafari, isFirefox, isEdge, isChrome, isSamsung, ios, android, windows, macOS };
}

export default function getDeviceFromRequest(req, options) {
    let userAgent = '';
    if (typeof req !== 'undefined') {
        userAgent = req.headers['user-agent'];
    } else if (typeof navigator !== 'undefined') {
        userAgent = navigator.userAgent;
    }
    if (!userAgent) {
        userAgent = options.defaultUserAgent;
    }
    const { mobile, mobileOrTablet, isSafari, isFirefox, isEdge, isChrome, isSamsung, ios, android, windows, macOS } = extractDevices(req, userAgent);
    const hasTouch = typeof window === 'undefined' ? mobileOrTablet : 'ontouchstart' in window || window.navigator.maxTouchPoints > 0;

    return {
        extractDevices,
        userAgent,
        isMobile: mobile,
        isMobileOrTablet: mobileOrTablet,
        isTablet: !mobile && mobileOrTablet,
        isDesktop: !mobileOrTablet,
        isDesktopOrTablet: !mobile,
        isIos: ios,
        isAndroid: android,
        isWindows: windows,
        isMacOS: macOS,
        isSafari,
        isFirefox,
        isEdge,
        isChrome,
        isSamsung,
        //   isCrawler
        hasTouch,
    };
}
