import qs from 'qs';
import type { NuxtAxiosInstance } from '@nuxtjs/axios';
import { Context } from '@nuxt/types';
import { defineNuxtPlugin } from '@/types/nuxt2CompositionApiShims';

export default defineNuxtPlugin(({
  $config,
  $axios,
  req
}, inject) => {
  processAxiosInstance($axios, req);

  let endpoint;

  if ($config.ENVIRONMENT === 'local') {
    if (process.client) {
      endpoint = `${ location.origin }`;
    } else {
      endpoint = 'https://lk.stranadev-new.com';
    }
  } else if (process.client) {
    endpoint = $config.CLIENT_API_URL;
  } else {
    endpoint = $config.SERVER_API_URL;
  }

  $axios.setBaseURL(endpoint);
  $axios.defaults.timeout = 30 * 1000;

  $axios.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    if (error?.response?.status) {
      return Promise.reject(error);
    }

    if (!error.status) { // при Network Error нет статуса
      error.message = 'Произошла ошибка. Попробуйте через несколько минут.';
    }

    return Promise.reject(error);
  });

  const portal = $axios.create({
    withCredentials: false
  });

  processAxiosInstance(portal, req);

  let portalEndpoint;

  if ($config.ENVIRONMENT === 'local') {
    if (process.client) {
      portalEndpoint = `${ location.origin }`;
    } else {
      portalEndpoint = 'https://stranadev-new.com';
    }
  } else if (process.client) {
    portalEndpoint = `https://${ $config.MAIN_SITE_HOST }`;
  } else {
    portalEndpoint = 'http://backend:8000';
  }

  portal.setBaseURL(portalEndpoint + '/graphql/');
  portal.defaults.timeout = 30 * 1000;

  inject('portal', portal);
});

function processAxiosInstance (axios: NuxtAxiosInstance, req: Context['req']): void {
  axios.defaults.paramsSerializer = (params): string => qs.stringify(params, { arrayFormat: 'repeat' });
  axios.defaults.xsrfCookieName = 'csrftoken';
  axios.defaults.xsrfHeaderName = 'X-CSRFToken';
  const headers = req && req.headers ? ({ ...req.headers }) : {};
  axios.setHeader('X-Forwarded-Host', headers['x-forwarded-host'] as string);
  axios.setHeader('X-Forwarded-Port', headers['x-forwarded-port'] as string);
  axios.setHeader('X-Forwarded-Proto', headers['x-forwarded-proto'] as string);
}
