import { useVuelidate } from '@vuelidate/core';
import {
  type Ref,
  ref
} from 'vue';
import {
  storeToRefs
} from 'pinia';
import mime from 'mime-types';
import { FileFromBackend } from '@/types/UploadDocuments';
import {
  BASIC_FILE_INPUT_VALIDATION
} from '@/shared/@forms/variables';
import { useUploadDocuments } from '@/store/uploadDocuments';
import {
  useSentry
} from '@/app/plugins/app-context';
import { useMortgageStore } from '@/store/mortgage';

export type ServerErrors = Record<string, string>;

export interface FormItem {
  component: string;
  mask?: string;
  field: string;
  placeholder?: string;
  label?: string;
  errorMessage?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  withCheckbox?: boolean;
  min?: number;
  max?: number;
  step?: number;
  multiple?: boolean;
  limit?: number;
  maxSize?: number;
  accept?: string;
  slug?: string;
  type?: string;
  class?: string;
  rows?: string;
  customFiles?: Array<FileFromBackend>;
  icon?: string;
  participantTypeSlug?: string;
}

export interface CategoryItem {
  index: number | string;
  name: string;
  items: Array<FormItem>;
}

interface useBaseFormArgument {
  serverErrors: ServerErrors;
  v$: ReturnType<typeof useVuelidate>;
  bookingId?: number;
}

export function useBaseForm (argument: useBaseFormArgument): {
  getError(field: string, property?: string, multiLevel?: boolean, multiLevelIndex?: number | string): string | undefined;
  uploadDocumentsHandler(slug: string, files: FileList, participantTypeSlug?: string, participantNumber?: number | string): Promise<void>;
  removeDocumentsHandler(file: Array<FileFromBackend>, slug: string, isRemoveAll?: boolean): Promise<void>;
  filesChangeHandler(files: FileList, slug: string, field: string, participantTypeSlug?: string, participantNumber?: number | string): Promise<void>;
  removeFileHandler(file: FileFromBackend, slug: string): Promise<void>;
  removeAllFilesHandler(slug: string): Promise<void>;
  updateModelValue(files: FileList, field: string, index?: number | string): void;
  isFilesUploading: Ref<boolean>;
} {
  const sentry = useSentry();
  const {
    serverErrors,
    v$,
    bookingId
  } = argument ?? {};

  const uploadDocumentsStore = useUploadDocuments();
  const {
    documentsSlug,
    uploadedDocuments
  } = storeToRefs(uploadDocumentsStore);
  const {
    uploadDocuments,
    deleteDocuments
  } = uploadDocumentsStore;
  const mortgageStore = useMortgageStore();
  const {
    mortgageTicketId
  } = storeToRefs(mortgageStore);

  const getDocumentId = (documentSlug: string): number | undefined => {
    const document = documentsSlug.value.find((item) => item.slug === documentSlug);

    if (!document) {
      sentry.captureMessage('useBaseForm.ts ~ getDocumentId ~ document slug not found', {
        tags: {
          cabinet: 'error'
        }
      });

      return undefined;
    }

    return document.id;
  };

  const validateFileType = (file: File, mimeTypes: Array<string>): boolean => {
    const fileType = mime.lookup(file.name);

    return fileType ? mimeTypes.includes(fileType) : false;
  };

  const setFormData = (files: FileList): FormData | null => {
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      const isValid = validateFileType(files[i], BASIC_FILE_INPUT_VALIDATION.MIME_TYPES);

      if (files[i]?.lastModified && isValid) {
        formData.append('files[]', files[i]);
      }
    }

    // FIXME: entries в formData появилось после ts 4.9.5. Надо будет убрать any, когда обновим TS
    // eslint-disable-next-line
    if (Array.from((formData as any).entries()).length > 0) {
      return formData;
    }

    return null;
  };

  const uploadDocumentsHandler = async (
    slug: string,
    files: FileList,
    participantTypeSlug?: string,
    participantNumber?: number | string
  ): Promise<void> => {
    const documentId = getDocumentId(slug);

    if (!documentId || !bookingId) {
      return;
    }

    if (files.length > 0) {
      const formData = setFormData(files);
      await uploadDocuments(
        formData, documentId, bookingId, mortgageTicketId?.value, participantTypeSlug, participantNumber
      );
    } else {
      await uploadDocuments(
        null, documentId, bookingId, mortgageTicketId?.value, participantTypeSlug, participantNumber
      );
    }
  };

  const removeDocumentsHandler = async (
    files: Array<FileFromBackend>,
    slug: string,
    isRemoveAll?: boolean
  ): Promise<void> => {
    const documentId = getDocumentId(slug);

    if (!documentId || !bookingId) {
      return;
    }

    const ids = files.map((file) => file.id);

    if (ids?.length > 0) {
      if (isRemoveAll && uploadedDocuments?.value) {
        uploadedDocuments.value[slug] = [];
      }
      await deleteDocuments(ids, documentId, bookingId);
    }
  };

  const isFilesUploading = ref(false);

  const filesChangeHandler = async (
    files: FileList,
    slug: string,
    field: string,
    participantTypeSlug?: string,
    participantNumber?: number | string
  ): Promise<void> => {
    if (participantNumber) {
      v$.value.files[participantNumber][field].$model = files;
    } else {
      v$.value.files[field].$model = files;
    }

    isFilesUploading.value = true;
    await uploadDocumentsHandler(slug, files, participantTypeSlug, participantNumber);
    isFilesUploading.value = false;

    v$.value.$reset();
  };

  const removeFileHandler = async (file: FileFromBackend, slug: string): Promise<void> => {
    if (file?.id) {
      isFilesUploading.value = true;
      await removeDocumentsHandler([file], slug);
      isFilesUploading.value = false;
    }
  };

  const removeAllFilesHandler = async (slug: string): Promise<void> => {
    isFilesUploading.value = true;

    if (uploadedDocuments?.value) {
      await removeDocumentsHandler(uploadedDocuments.value[slug], slug, true);
    }
    isFilesUploading.value = false;
  };

  const updateModelValue = (files: FileList, field: string, index?: number | string): void => {
    if (index) {
      v$.value.files[index][field].$model = files;
    } else {
      v$.value.files[field].$model = files;
    }
  };

  const getError = (
    field: string,
    property: string = 'form',
    multiLevel = false,
    multiLevelIndex: number | string = 0
  ): string | undefined => {
    const validationTypes = [
      'required',
      'numeric',
      'minLength',
      'maxLength',
      'alpha',
      'isPhone',
      'sameAsOldPhone',
      'isEmail',
      'birthDate',
      'isOlderThan',
      'fileExtension',
      'fileMaxSize',
      'fileMaxCount',
      'serverError'
    ];

    let validation;

    if (multiLevel) {
      validation = v$.value[property]?.[multiLevelIndex]?.[field] ?? {};
    } else {
      validation = v$.value[property][field] ?? {};
    }

    if (!validation.$dirty) {
      return undefined;
    }

    for (const type of validationTypes) {
      if (validation[type]?.$invalid) {
        if (type === 'serverError') {
          return serverErrors[field] || 'serverErrorLabel';
        }

        return `fields.${ field }.errors.${ type }`;
      }
    }

    return undefined;
  };

  return {
    getError,
    uploadDocumentsHandler,
    removeDocumentsHandler,
    filesChangeHandler,
    removeFileHandler,
    removeAllFilesHandler,
    updateModelValue,
    isFilesUploading
  };
}
