import { defineStore } from 'pinia';
import { useCitiesStore } from '@/store/cities';

interface CityResponse {
  id: number;
  name: string;
  slug: string;
  phone: string;
}

interface Office {
  city: string;
  phone: string;
}
interface State {
  offices: Array<Office>;
}

export const useOfficesStore = defineStore('offices', {
  state: (): State => ({
    offices: []
  }),

  getters: {
    phone (state) {
      const citiesStore = useCitiesStore();
      const currentCity = citiesStore.currentCity?.value ?? undefined;

      return state.offices?.find(({ city }: Office) => city === currentCity)?.phone;
    }
  },

  actions: {
    async getOffices (): Promise<void> {
      try {
        const { data } = await this.$axios.get<Array<CityResponse>>('api/cities');

        if (!Array.isArray(data)) {
          this.offices = [];

          return;
        }

        this.offices = data.map<Office>(({
          slug: city,
          phone
        }) => {
          return {
            city,
            phone
          };
        });
      } catch (error) {
        this.$sentry.captureException(error);
        console.error('🚀 ~ file: offices.ts ~ getOffices ~ e', error);
      }
    }
  }
});
