interface BasicFileInputValidation {
  MAX_SIZE: number;
  MAX_SIZE_BYTES: number;
  MAX_COUNT: number;
  MIME_TYPES: Array<string>;
}

export const BASIC_FILE_INPUT_VALIDATION: BasicFileInputValidation = {
  MAX_SIZE: 5,
  MAX_SIZE_BYTES: 5 * 1024 * 1024,
  MAX_COUNT: 10,
  MIME_TYPES: ['image/png', 'image/jpeg', 'application/pdf']
};

export const DOCUMENT_SLUG = {
  PASSPORT: 'mortgage_passport',
  MARRIAGE_CERTIFICATE: 'mortgage_marriage_certificate',
  INN: 'mortgage_inn',
  SNILS: 'mortgage_snils',
  CERTIFICATES: 'mortgage_certificates',
  CHILD_BIRTH_CERTIFICATE: 'mortgage_child_birth_certificate',
  REGISTRATION: 'registration',
  AUTHORITY: 'authority',
  CO_BORROWERS: 'mortgage_co_borrowers',
  NDFL_2: 'mortgage_ndfl_2',
  LABOR_BOOK: 'mortgage_labor_book'
};

export const PARTICIPANT_TYPE = {
  SPOUSE: 'spouse',
  CHILD_AFTER_14: 'childAfter14',
  CHILD_BEFORE_14: 'childBefore14',
  ANOTHER: 'another'
} as const;

export const RESIDENT_STATUS = {
  RESIDENT: 'resident',
  NON_RESIDENT: 'non-resident'
} as const;
