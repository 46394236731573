// region Imports
import type { NuxtAxiosInstance } from '@nuxtjs/axios';
import type {
  PrivilegeInfo
} from './types';

import routes from './routes';

import { BaseClient } from '@/shared/api/Base/Base.client';
import { useCabinetsApi } from '@/app/plugins/app-context';

// endregion

export class PrivilegeClient extends BaseClient {
  private static instance: PrivilegeClient;

  public static getInstance (apiInstance: NuxtAxiosInstance): PrivilegeClient {
    if (!PrivilegeClient.instance) {
      PrivilegeClient.instance = new PrivilegeClient(apiInstance);
    }

    return PrivilegeClient.instance;
  }

  async getPrivilegeInfo (): Promise<Array<PrivilegeInfo> | undefined> {
    try {
      return await this.sendGetRequest(routes.info);
    } catch (err) {
      console.error('🚀 ~ file: privilege.ts ~ getPrivilegeInfo ~ e', err);
      this.sentry.captureException(err);
    }
  }
}

export const useAuthClientService = (): PrivilegeClient => PrivilegeClient.getInstance(useCabinetsApi());
