
import {
  defineComponent,
  type PropType
} from 'vue';
import {
  AreaOutput,
  PlanOutput,
  PriceOutput,
  RoomOutput
} from '@strana-artw/cabinets-common';
import {
  type FlatParams
} from '@/types/FlatParams';

export default defineComponent({
  name: 'FavoritesModalBuildingInfo',

  components: {
    PlanOutput,
    PriceOutput,
    AreaOutput,
    RoomOutput
  },

  props: {
    info: {
      type: Object as PropType<FlatParams>,
      default: null
    }
  }
});
