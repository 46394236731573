import { remToPx } from '@/shared/lib/utils/units';

export const SCREEN_BREAKPOINTS = {
  xs: 0,
  sm: 768,
  md: 992,
  lg: 1280,
  xl: 1440
} as const;

export const BREAKPOINTS_SIDE_PADDINGS = {
  xs: 16,
  sm: 20,
  md: remToPx('3.2rem'),
  lg: remToPx('4rem'),
  xl: remToPx('5.6rem')
};
