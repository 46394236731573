
import { mapActions } from 'pinia';
import { SButton } from '@strana-artw/ui-kit';
import { useHeaderStore } from '@/store/header';

export default {

  components: {
    SButton
  },

  layout: 'errorLayout',

  props: {
    error: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    errorTitle () {
      return this.error.statusCode === 503 ? 'Тех. работы' : this.error.statusCode;
    },

    errorText () {
      const code = this.error.statusCode;

      if (code === 404) {
        return 'Такой страницы не существует или мы ее еще не построили...';
      } else if (code === 503) {
        return 'Мы делаем сайт лучше, осталось немного. Попробуйте вернуться позже... ';
      }

      return 'Произошла внутренняя ошибка сервера, требуется перезагрузка...';
    },

    buttonText () {
      return this.error.statusCode === 404 ? 'Открыть меню' : 'Перезагрузить';
    }
  },

  methods: {
    ...mapActions(useHeaderStore, [
      'setHeaderMenuState'
    ]),

    handleClick () {
      this.error.statusCode === 404 ? this.setHeaderMenuState(true) : window.location.href = '/';
    }
  }

};
