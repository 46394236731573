import { defineStore } from 'pinia';
import {
  tryGetFromStorage,
  trySaveToStorage
} from '@/shared/lib/utils/storageUtils';

interface CityResponse {
  id: number;
  name: string;
  slug: string;
}

interface City {
  label: string;
  value: string;
}

interface State {
  cities: Array<City>;
  currentCity?: City;
}

interface CurrentCityResponse {
  id: number;
  name: string;
  slug: string;
}

const CURRENT_CITY_KEY = 'currentCity';
const DEFAULT_CITY_SLUG = 'tmn';

export const useCitiesStore = defineStore('cities', {
  state: (): State => ({
    cities: [],
    currentCity: undefined
  }),

  getters: {
    portalHostWithCity (): string {
      return `//${ process.env.HOST }/${ this.currentCity?.value }/`;
    }
  },

  actions: {
    async getCities (): Promise<void> {
      const hasCities = Boolean(this.cities.length);

      if (hasCities) {
        return;
      }

      try {
        const {
          data
        }: {data: Array<CityResponse>} = await this.$axios.get('api/cities');

        // Приводим в корректный вид
        this.cities = data.map<City>(({
          name,
          slug
        }) => {
          return {
            label: name,
            value: slug
          };
        });
      } catch (error) {
        this.$sentry.captureException(error);
        console.error('🚀 ~ file: cities.ts ~ getCities ~ e', error);
      }
    },

    async getCurrentCity (): Promise<void> {
      if (this.currentCity) {
        return;
      }

      const localStored = tryGetFromStorage('localStorage', CURRENT_CITY_KEY);

      if (!localStored.success) {
        console.error('🚀 ~ file: cities.ts ~ getCurrentCity ~ e', localStored.error);
        this.$sentry.captureException(localStored.error);
      } else if (localStored.value) {
        await this.getCities();

        const foundCity = this.findCityInStore(localStored.value);

        if (foundCity) {
          this.currentCity = foundCity;

          return;
        }
      }

      try {
        const {
          data: {
            name,
            slug
          }
        }: {data: CurrentCityResponse} = await this.$axios.get('api/cities/current');

        this.currentCity = {
          label: name,
          value: slug
        };
      } catch (error) {
        this.$sentry.captureException(error);
        console.error('🚀 ~ file: cities.ts ~ getCurrentCity ~ e', error);
      }
    },

    setCurrentCity (cityValue: string): void {
      let city = this.findCityInStore(cityValue);

      if (!city) {
        city = this.findCityInStore(DEFAULT_CITY_SLUG);
      }

      this.currentCity = city;

      if (this.currentCity) {
        const localStored = trySaveToStorage('localStorage', CURRENT_CITY_KEY, this.currentCity.value);

        if (!localStored.success) {
          console.error('🚀 ~ file: cities.ts ~ setCurrentCity ~ e', localStored.error);
        }
      }
    },

    findCityInStore (cityValue: string): City | undefined {
      return this.cities.find(({ value }: City) => value === cityValue);
    }
  }
});
