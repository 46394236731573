import { defineStore } from 'pinia';
import {
  ref
} from 'vue';
import type { NuxtAxiosInstance } from '@nuxtjs/axios';
import getErrorMessageTyped from '@@/common/services/getErrorMessageForNotificationDataTyped';
import {
  useCabinetsApi,
  useSentry
} from '@/app/plugins/app-context';
import { useCitiesStore } from '@/store/cities';
import type { SchemasType } from '@/shared/types/openapi';

export type SurveyResponse = SchemasType<'SurveyListResponse'>;
export type QuestionsResponse= SchemasType<'_QuestionsListResponse'>

export type AnswersValue = string | Array<string> | undefined
type AnswersType = Record<string, AnswersValue>;

export const SLUGS_SURVEY = {
  selectionApartments: 'selection_of_apartments'
};

export const STAGE_SURVEY = {
  survey: 'SurveyModalQuestions',
  success: 'SurveyModalSuccess'
};

const defaultSurvey = {
  questions: [],
  title: '',
  description: '',
  slug: ''
};

export const useSurveyStore = defineStore('survey', () => {
  const axios: NuxtAxiosInstance = useCabinetsApi();
  const sentry = useSentry();

  const isModalOpen = ref<boolean>(false);
  const surveyStage = ref<string>(STAGE_SURVEY.survey);
  const survey = ref<SurveyResponse>(defaultSurvey);
  const answers = ref<AnswersType>({});

  async function reset (): Promise<void> {
    surveyStage.value = STAGE_SURVEY.survey;
    isModalOpen.value = false;
    answers.value = {};
    survey.value = defaultSurvey;
  }
  async function openModalSurvey (slug: string): Promise<void> {
    try {
      await getSurvey(slug);
      isModalOpen.value = true;
    } catch (error) {
      console.error('🚀 ~ file: survey.ts ~ openModalSurvey ~ e', error);
      sentry.captureException(error);
    }
  }

  function closeModalSurvey (): void {
    isModalOpen.value = false;
  }

  async function getSurvey (slug: string): Promise<void> {
    try {
      const citiesStore = useCitiesStore();
      const city = citiesStore.currentCity?.value ?? undefined;

      const url = `/api/survey/${ slug }`;
      const { data } = await axios.get<SurveyResponse>(url, {
        params: {
          city
        }
      });

      if (!data) {
        return;
      }

      survey.value = data;

      const questions: Array<QuestionsResponse> = survey.value.questions;

      for (const question of questions) {
        const isDefault = question.isDefault as AnswersValue;

        if (isDefault) {
          if (question.type === 'single') {
            saveAnswer(question.slug, isDefault[0]);
          } else {
            saveAnswer(question.slug, isDefault);
          }
        }
      }
    } catch (error) {
      console.error('🚀 ~ file: survey.ts ~ getSurvey ~ e', error);
      sentry.captureException(error);
      const errorMessage = getErrorMessageTyped(error);
      throw new Error(errorMessage);
    }
  }

  function saveAnswer (questionSlug: string, answerSlug: AnswersValue): void {
    const answer: AnswersType = {};
    answer[questionSlug] = answerSlug;
    answers.value[questionSlug] = answerSlug;
  }

  async function sendSurvey (slug: string): Promise<void> {
    try {
      const citiesStore = useCitiesStore();
      const city = citiesStore.currentCity?.value ?? undefined;
      const currentUrl = window.location.href;

      const url = `/api/survey/${ slug }/user_answer/`;
      await axios.post(url, {
        data: answers.value,
        citySlug: city
      }, {
        headers: { 'Applied-from-url': currentUrl }
      });
    } catch (error) {
      console.error('🚀 ~ file: survey.ts ~ sendSurvey ~ e', error);
      sentry.captureException(error);
    }
  }

  async function changedStage (slug: keyof typeof STAGE_SURVEY): Promise<void> {
    surveyStage.value = STAGE_SURVEY[slug];
  }

  return {
    isModalOpen,
    survey,
    surveyStage,
    answers,
    openModalSurvey,
    closeModalSurvey,
    saveAnswer,
    sendSurvey,
    changedStage,
    reset
  };
});
