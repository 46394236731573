import {
  cleanPhone
} from '@/shared/lib/utils/helpers';
import {
  capitalizeFirstLetter,
  initial
} from '@/shared/lib/utils/textUtils';

function unformattedBirthdate (
  birthdate
) {
  return birthdate
    ?.split('-')
    ?.reverse()
    ?.join('.') ?? '';
}

function unformattedPassport (
  passportSeries,
  passportNumber
) {
  return `${ passportSeries?.slice(0, 2) }${ passportSeries?.slice(2, 4) }${ passportNumber }`;
}

function formattedBirthdate (
  birthdate
) {
  return birthdate
    ?.split('.')
    ?.reverse()
    ?.join('-') ?? '';
}

function formattedPassport (
  passport
) {
  const passportTrimmed = passport?.replace(/\s/g, '');
  let passportSeries = null;
  let passportNumber = null;

  if (passportTrimmed?.length) {
    passportSeries = passportTrimmed.slice(0, 4);
    passportNumber = passportTrimmed.slice(4);
  }

  return {
    passportSeries,
    passportNumber
  };
}

export function unformatUserPersonal ({
  email = '',
  name = '',
  patronymic = '',
  surname = '',
  phone = '',
  birth_date: birthdate = '',
  passport_series: passportSeries = null,
  passport_number: passportNumber = null
}) {
  return {
    name: name || '',
    phone: phone || '',
    email: email || '',
    surname: surname || '',
    patronymic: patronymic || '',
    birthDate: unformattedBirthdate(birthdate),
    passport: passportSeries && passportNumber ? unformattedPassport(passportSeries, passportNumber) : null
  };
}

export function formatUserPersonal ({
  phone = '',
  email = '',
  name = '',
  patronymic = '',
  surname = '',
  passport = null,
  birthDate: birthdate = ''
}) {
  const {
    passportSeries = null,
    passportNumber = null
  } = formattedPassport(passport) ?? {};

  return {
    name,
    email,
    surname,
    patronymic,
    passport_series: passportSeries,
    passport_number: passportNumber,
    phone: phone ? cleanPhone(phone) : '',
    birth_date: formattedBirthdate(birthdate)
  };
}

const USER_FIELDS = [
  'name',
  'patronymic',
  'surname',
  'passport',
  'email',
  'birthDate'
];
class UserUpdateError {
  constructor (error) {
    let errorReason = 'unknown';
    let errorMessage = 'Ошибка при обновлении данных';
    const {
      data
    } = error?.response || {};

    if (
      Boolean(data) &&
      typeof data === 'object' &&
      Boolean(Object.keys(data).length)
    ) {
      const {
        reason,
        message
      } = data;
      const field = USER_FIELDS.reduce((acc, val) => {
        if (reason?.includes(val)) {
          acc = val;
        }

        return acc;
      }, '');

      if (reason) {
        errorReason = field;
      }

      if (message) {
        errorMessage = message;
      }
    }
    this.ok = false;
    this.reason = errorReason;
    this.message = errorMessage;
  }
}

export async function updateUser (payload) {
  try {
    const values = formatUserPersonal(payload);
    const patchUser = await this.$axios.$patch('/api/users/partial_update', values);
    await this.$auth.setUser(patchUser);

    return {
      ok: true,
      message: 'Данные успешно сохранены'
    };
  } catch (error) {
    return new UserUpdateError(error);
  }
}

export async function changeUserPhone (payload) {
  const url = '/api/users/change_phone';
  try {
    const userData = await this.$axios.$patch(url, payload);
    await this.$auth.setUser(userData);
    this.success = true;
  } catch (e) {
    let codeError = 'Ошибка при отправке кода. Попробуйте снова.';

    if (e?.response?.data?.message) {
      codeError = e.response.data.message;
    }
    this.$set(this.serverErrors, 'code', codeError);
  }
}

export async function sendCode (payload) {
  const url = '/api/users/send_code';
  try {
    const {
      token
    } = await this.$axios.$post(url, payload);
    this.token = token;
  } catch (e) {
    console.log('🚀 ~ file: services/user.js ~ sendCode ~ e', e);
  }
}

export function getMeFromStore () {
  return unformatUserPersonal(this.$store.state.auth.user);
}

export function userNameWithInitialsFormatted () {
  const {
    surname,
    name,
    patronymic
  } = this.$store.state.auth.user;
  const surnameCapitalized = capitalizeFirstLetter(surname);
  const nameInitial = initial(name);
  const patronymicInitial = initial(patronymic);
  let userName = '';

  if (surnameCapitalized) {
    userName += surnameCapitalized;
  }

  if (nameInitial) {
    userName += ` ${ nameInitial }.`;
  }

  if (patronymicInitial) {
    userName += `${ patronymicInitial }.`;
  }

  return userName;
}
