import { render, staticRenderFns } from "./CallbackModalForm.vue?vue&type=template&id=68fdf3c6"
import script from "./CallbackModalForm.vue?vue&type=script&lang=ts"
export * from "./CallbackModalForm.vue?vue&type=script&lang=ts"
import style0 from "./CallbackModalForm.vue?vue&type=style&index=0&id=68fdf3c6&prod&lang=scss&module=true"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

/* custom blocks */
import block0 from "./CallbackModalForm.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FavoritesModalBuildingInfo: require('/app/src/components/pages/favorites/FavoritesModalBuildingInfo.vue').default})
