
import {
  defineComponent
} from 'vue';
import {
  SButton,
  SModal
} from '@strana-artw/ui-kit';

export default defineComponent({
  name: 'CallbackModalSuccess',

  components: {
    SButton,
    SModal
  },

  model: {
    prop: 'open',
    event: 'update:open'
  },

  props: {
    open: {
      type: Boolean,
      default: false
    },

    title: {
      type: String,
      default: undefined
    },

    description: {
      type: String,
      default: undefined
    },

    buttonLabel: {
      type: String,
      default: undefined
    },

    autoClose: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      timer: undefined as unknown as ReturnType<typeof setTimeout>,
      closeTime: 5
    };
  },

  computed: {
    displayedTitle () {
      return this.title || this.$t('title');
    },

    displayedDescription () {
      return this.description || this.$t('description');
    },

    displayedButtonLabel () {
      return this.buttonLabel || this.$t('buttonLabel');
    }
  },

  watch: {
    open (newValue, oldValue) {
      const hasTimer = this.autoClose && newValue && newValue !== oldValue;

      if (hasTimer) {
        this.timer = setInterval(() => {
          this.closeTime -= 1;

          if (this.closeTime === 0) {
            clearInterval(this.timer);
            this.closeModal();
            this.closeTime = 5;
          }
        }, 1000);
      }
    }
  },

  methods: {
    cancel () {
      this.closeModal();
    },

    closeModal () {
      this.$emit('update:open', false);
    }
  }
});
