import { defineStore } from 'pinia';
import {
  ref,
  useContext
} from '@nuxtjs/composition-api';
import type {
  StateChanger
} from 'vue-infinite-loading';
import type {
  PageInfo
} from '@/types/PageInfo';
import type {
  Document,
  Root
} from '@/types/Documents';
import { useCitiesStore } from '@/store/cities';

export const useDocumentsStore = defineStore('documents', () => {
  const {
    $axios,
    $sentry
  } = useContext();
  const documents = ref<Document>({
    page: undefined,
    nextPage: undefined,
    result: []
  });

  async function getDocuments (): Promise<void> {
    try {
      const cities = useCitiesStore();
      const {
        value: city
      } = cities.currentCity ?? {};

      if (!city) {
        return;
      }

      const url = '/api/documents/interactions';
      const { data } = await $axios.get<Root>(url, {
        params: {
          city,
          menu_type: 'lk_client'
        }
      });
      documents.value.result = data.result;
    } catch (error) {
      console.error('🚀 ~ file: documents.ts ~ getDocuments ~ error', error);
      $sentry.captureException(error);
    }
  }

  async function getNextPage ({
    page,
    infiniteState
  }: { page: PageInfo; infiniteState: StateChanger }): Promise<void> {
    try {
      if (!page.next_page) {
        return;
      }

      const { data } = await $axios.get<Root>(page.next_page);
      const {
        page_info: pageInfo,
        result
      } = data;

      if (!pageInfo) {
        throw new Error('page info not received');
      }

      if (result?.length) {
        documents.value.page = pageInfo;
        documents.value.nextPage = pageInfo.next_page;
        documents.value.result.push(...result);

        if (!pageInfo.next_page) {
          infiniteState.complete();
        } else {
          infiniteState.loaded();
        }
      } else {
        infiniteState.loaded();
      }
    } catch (error) {
      console.log('🚀 ~ file: documents.ts ~ getNextPage ~ error', error);
      infiniteState.complete();
      $sentry.captureException(error);
    }
  }

  return {
    documents,
    getDocuments,
    getNextPage
  };
}
);
