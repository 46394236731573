var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"headerNavigationInlineRef",class:[
    _setup.$style.HeaderNavigationInline,
    _setup.displayClasses
  ],attrs:{"id":"header-navigation-inline"}},[_c('menu',{ref:"menuRef",class:_setup.$style.menu},[_vm._l((_setup.inlineLinks),function({
        id,
        link,
        name,
      }){return _c('li',{key:id},[_c(_setup.HoverableLink,{class:_setup.$style.link,attrs:{"to":link?.path ?? '',"text":name},scopedSlots:_vm._u([{key:"text",fn:function(){return [_vm._v("\n          "+_vm._s(name)+"\n        ")]},proxy:true}],null,true)})],1)}),_vm._v(" "),_c('li',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_setup.closeDropdown),expression:"closeDropdown"}],class:_setup.$style.dropdownItem},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(_setup.isButtonShown),expression:"isButtonShown"}],ref:"dropdownButtonRef",class:[
          _setup.$style.link,
          _setup.$style.dropdownButton,
          _setup.displayClasses
        ],on:{"click":function($event){$event.preventDefault();return _setup.toggleDropdown.apply(null, arguments)}}},[_vm._v("\n        Ещё\n      ")]),_vm._v(" "),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_setup.isDropdown),expression:"isDropdown"}],class:[
          _setup.$style.columnList,
          _setup.$style[`m-${ _vm.menuPosition }`]
        ]},_vm._l((_setup.dropdownLinks),function({
            id,
            link,
            name,
          }){return _c('li',{key:id,class:_setup.$style.columnItem},[_c('NuxtLink',{attrs:{"to":link?.path ?? ''}},[_vm._v("\n            "+_vm._s(name)+"\n          ")])],1)}),0)])],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }